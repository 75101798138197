@mixin mdb-timepicker-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $surface: map-get($theme, surface);
  $onSurface: map-get($theme, onSurface);
  $onPrimary: map-get($theme, onPrimary);
  $onSecondary: map-get($theme, onSecondary);
  $error: map-get($theme, error);
  $onError: map-get($theme, onError);
  $success: map-get($theme, success);
  $onSuccess: map-get($theme, onSuccess);
  $surfaceHover: map-get($theme, surfaceHover);
  $pickerHeader: map-get($theme, pickerHeader);
  $onLink: map-get($theme, onLink);
  $onBackground: map-get($theme, onBackground);
  $surfaceDisabled: map-get($theme, dropdownDisabled);
  $timepickerClockBg: map-get($theme, timepickerClockBg);
  $timepickerClockText: map-get($theme, timepickerClockText);

  .timepicker {
    &-circle {
      border-color: $primary;
    }

    &-elements {
      background: $surface;
    }

    &-head {
      background-color: $pickerHeader;
    }

    &-button {
      color: $onSurface;

      &:hover {
        background-color: $surfaceHover;
      }

      &:focus {
        background-color: $surfaceHover;
      }
    }

    &-submit-inline {
      color: $onSurface;
    }

    &-current {
      color: $onSurface;
    }

    &-mode-wrapper {
      color: rgba($onSurface, 0.54);
    }

    &-clock {
      color: $timepickerClockText;
      background-color: $timepickerClockBg;
    }

    &-time-tips-minutes,
    &-time-tips-inner,
    &-time-tips-hours {
      &.active {
        color: $onPrimary;
        background-color: $primary;
      }

      &.disabled {
        color: $surfaceDisabled;
      }
    }

    &-current,
    &-dot {
      color: $onSurface;
    }

    &-middle-dot {
      background-color: $primary;
    }

    &-hand-pointer {
      background-color: $primary;
    }

    &-time-tips {
      &.active {
        color: $onSurface;
      }
    }

    &-hour-mode {
      color: $onSurface;
    }

    &-dot {
      color: $onPrimary;
    }

    &-hour-mode,
    &-hour,
    &-minute {
      color: $onPrimary;

      &:hover,
      &:focus {
        background-color: $onLink;
      }

      &.active {
        color: $onPrimary;
      }
    }

    &-footer {
      background-color: $surface;
    }

    &-toggle-button {
      color: $onBackground;

      &:hover {
        color: $primary;
      }

      &:focus {
        color: $primary;
      }
    }

    &-input:focus + &-toggle-button,
    &-input:focus + &-toggle-button i {
      color: $primary;
    }
  }

  // Additional MDB Angular styles
  .timepicker-canvas line {
    stroke: $primary;
  }

  .timepicker-canvas-bearing {
    fill: $primary;
  }

  .timepicker-canvas-bg {
    fill: $primary;
  }
  // Additional MDB Angular styles
}
