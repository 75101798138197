@import "helper";

.btn:not(.btn-floating){
  border-radius: $border-radius;
}

.btn-group, .btn-group:hover .btn-group-vertical, .btn-group-vertical:hover,
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .btn-primary.show{
  box-shadow: none!important;
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle{
  background-color: color('btn-active')!important;
  border-color: color('btn-active')!important;
}

.btn-primary{
  background-color: color('btn')!important;
  border-color: color('btn')!important;
}

.btn-primary:hover{
  background-color: color('btn-hover')!important;
  border-color: color('btn-hover')!important;
}

.btn-primary:active, .btn-primary.active, .btn-primary.show{
  background-color: color('btn-active')!important;
  border-color: color('btn-active')!important;
}

.sidenav-link.active{
  background-color: rgba(251, 251, 251, 0.05) !important;
  color: color('purple')!important;
  font-weight: bold;
}

.sidenav-link.active>i{
  color: color('purple')!important;
}

.custom-tabs mdb-tabs ul {
  background: white;
  border-radius: 0.5rem;
}

.custom-tabs mdb-tabs ul li:first-child a{
  border-bottom-left-radius: 0.5rem!important;
}

.custom-tabs mdb-tabs .nav-tabs .nav-item .nav-link.active {
  font-weight: bold!important;
}

.btn-primary:disabled {
  background-color: grey !important;
}

mdb-option span.option-text {
  font-size: 13px;
}

mdb-select-all-option span.select-option-text {
  font-size: 13px;

  input.form-check-input[type=checkbox] {
    margin-right: 10px;
  }
}

mdb-form-control + .error-message {
  top: 30px;
}
