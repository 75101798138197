$colors: (
  'purple': #7014f2,
  'green': #00f59b,
  'link': #0066F5,
  'black': #000,
  'btn': #7014f2,
  'btn-hover': #9E59FF,
  'btn-active': #9E59FF,
);

$border-radius: 0.5rem;

//color function
@function color($name) {
  @if map-has-key($colors, $name) == false {
    @error "Color #{$name} does not exist.";
  }
  @return map-get($colors, $name);
}

// px to rem function
@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}

@function rem($pxValue) {
  @return #{stripUnit($pxValue) / stripUnit(16px)}rem !important
}
