@import '@fortawesome/fontawesome-free/css/all.css';
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";
@import 'mdb-angular-treeview/scss/treeview.scss';
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "styles/theme";
@import "styles/mdb";
@import "styles/global";
body{
  font-family: "Poppins", sans-serif;
  background: rgba(245, 245, 245, 0.63);
}
