.word-break-word {
  word-break: break-word;
}

.mouse-pointer{
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input:not(.inputArrow)::-webkit-outer-spin-button,
input:not(.inputArrow)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


th{
  vertical-align: middle;
}
