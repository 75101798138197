@mixin mdb-pagination-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $surface: map-get($theme, surface);
  $onSurface: map-get($theme, onSurface);
  $onPrimary: map-get($theme, onPrimary);
  $onSecondary: map-get($theme, onSecondary);
  $error: map-get($theme, error);
  $onError: map-get($theme, onError);
  $success: map-get($theme, success);
  $onSuccess: map-get($theme, onSuccess);
  $surfaceHover: map-get($theme, surfaceHover);
  $pickerHeader: map-get($theme, pickerHeader);
  $onLink: map-get($theme, onLink);

  .page-link {
    color: $onSurface;

    &:hover {
      color: $onSurface;
      background: $onLink;
    }
    &:focus {
      color: $onSurface;
      background-color: $onLink;
    }
  }

  .page-item {
    &.active .page-link {
      color: $onPrimary;
      background-color: $primary;
    }

    &.disabled .page-link {
      background-color: $onLink;
    }
  }
}
